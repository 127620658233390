import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Button } from 'react-bootstrap';

import '../../generic-page.scss';

const CSGuidesSpenderPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Spender guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_spending.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Spender guide</h1>
          <h2>
            This guide will help you become a better minnow or dolphin spender.
          </h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="Disclaimer" />
        <p>
          If you decide to whale, please practice consumer responsibility. Gacha
          is fundamentally the same as gambling; my intention with creating this
          guide is not to promote impulsive spending but rather the opposite. My
          purpose for writing this is to narrow down the deals in the game to
          tell you the best bang for your buck. Your decision to spend is a
          decision that you alone are ultimately responsible for.
        </p>
        <SectionHeader title="Iridescent's Spending Guide" />
        <p>
          Due to the guide being massive, very detailed and also to allow the
          author to easily edit it, it will be kept in the original form as a
          google doc.
        </p>
        <p>Link to the guide:</p>
        <Button
          variant="primary"
          href="https://docs.google.com/document/d/1DUvz5KMEB0NrQB8wgRIrkGa0gePzfJ_KrkDeUqTBG7k/edit"
          target="_blank"
          rel="noreferrer"
        >
          Admin Coin Shop Guide
        </Button>
        <SectionHeader title="Blitz's Value Guide" />
        <p>
          Whitesushi's guide was created for SEA and while it wasn't updated in
          some time, Blitz created a fork and added the packages that are unique
          to the Global server.
        </p>
        <p>Link to the guide:</p>
        <Button
          variant="primary"
          href="https://docs.google.com/spreadsheets/d/1CiimK1-y6wjoLAJjkhTlGYtzanIk-BF2ZBPpa1PutZo/edit#gid=1374582730"
          target="_blank"
          rel="noreferrer"
        >
          AC Value Guide
        </Button>
      </div>
    </DashboardLayout>
  );
};

export default CSGuidesSpenderPage;

export const Head: React.FC = () => (
  <Seo
    title="Spender guide | Counter Side | Prydwen Institute"
    description="This guide will help you become a better minnow or dolphin spender."
  />
);
